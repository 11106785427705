<template>
  <div class="">
    <el-card class="mt14">
      <el-button type="primary" style="margin-bottom: 10px;" size="mini" @click="add">新增</el-button>
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        highlight-current-row
      >
         <el-table-column
          type="index"
          :index="getTabelIndex"
          width="100"
          label="序号"
        />
        <el-table-column prop="name" label="收货人姓名" min-width="120" />
        <el-table-column prop="mobile" label="收货人手机号" min-width="120" />
        <el-table-column prop="ip" label="省市区" min-width="120">
          <template slot-scope="scope">
            <div>
              {{ scope.row.province }}{{ scope.row.city
              }}{{ scope.row.district }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="收货人地址" min-width="120" />
        <el-table-column prop="brandList" label="品牌" min-width="120">
        <template slot-scope="scope">
          <div v-if="scope.row.brandList.length > 0">
            <span v-for="(item, index) in scope.row.brandList" :key="index"
              ><span v-if="index != 0">,</span>{{ item.name }}</span
            >
          </div>
        </template>
      </el-table-column>
        <el-table-column
          label="操作"
          min-width="120"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <!--<el-button type="text" size="small" @click="onEdit(scope.row.feedback_id)">备注</el-button>-->
            <el-button
              type="text"
              icon="el-icon-edit"
              size="small"
              @click="onDetails(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              size="small"
              @click="onDel(scope.row)"
              >删除</el-button
            >
            <!-- <el-button
                v-if="scope.row.status != 1"
                type="text"
                size="small"
                @click="handleReply(scope.row.feedback_id)"
              >回复</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>
    <el-dialog
      :title="form.id?'编辑':'新增'"
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="handleClose"
    >
      <el-form
        size="small"
        :model="form"
        ref="form"
        :rules="rules"
        label-width="120px"
      >
        <el-form-item label="收货人姓名：" prop="name">
          <el-input
            v-model="form.name"
            placeholder="请输入收货人姓名"
            class="input-with-select selWidth"
            clearable
            style="width: 300px"
          />
        </el-form-item>
        <el-form-item label="收货人手机号：" prop="mobile">
          <el-input
            style="width: 300px"
            v-model="form.mobile"
            maxlength="11"
            placeholder="请输入收货人手机号"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="省市区：" prop="cityList">
          <el-cascader
            style="width: 300px"
            v-model="form.cityList"
            :options="options"
            @change="handleChange"
          ></el-cascader>
        </el-form-item>

        <el-form-item label="品牌：" prop="brand">
          <el-select
            v-model="form.brand"
            multiple
            style="width: 300px"
            placeholder="请选择"
          >
            <el-option
              v-for="item in brandList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="收货人地址：" prop="address">
          <el-input
            style="width: 300px"
            type="textarea"
            v-model="form.address"
            placeholder="请输入收货人地址"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="submit()"
            >保存</el-button
          >
          <el-button size="small" @click="handleClose">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAddress,
  saveAddress,
  getRegionList,
  getSupplierAddressList,
  supplierAddressDel,
} from "@/api/supplierGoods/after";
import {
  goodBrandTotalListAll,
} from "@/api/goods/goods";
import { getBrandList } from "@/api/supplierGoods/goods";
import { getIndex } from "@/utils/other";
import { mobilePhoneRule } from "@/utils/formRules";
export default {
  name: "SettingAfter",
  components: {},
  data() {
    return {
      options: [],
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
      },
      tableData: {
        data: [{}],
        total: 0,
      },
      dialogVisible: false,
      listLoading: false,
      brandList: [],
      form: {
        name: "", //收货人姓名
        province: "", //收货人省
        city: "", //收货人是
        district: "", //收货人区
        address: "", //收货人地址
        mobile: "", //收货人手机号：
        cityList: [],
      },
      rules: {
        name: [
          { required: true, message: "请输入收货人姓名", trigger: "blur" },
        ],
        cityList: [
          { required: true, message: "请选择省市区", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入收货人手机号", trigger: "blur" },
          ...mobilePhoneRule,
        ],
        address: [
          { required: true, message: "请输入收货人地址", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getRegionList();
    this.getGoodBrandList();
  },
  methods: {
    getGoodBrandList() {
      goodBrandTotalListAll(2).then((res) => {
        this.brandList = res.data;
      });
      // getBrandList().then((res) => {
      //   if (res.code === 200) {
      //     this.brandList = res.data;
      //   }
      // });
    },
    getTabelIndex(index) {
      return getIndex(index, this.tableFrom.pageNum, this.tableFrom.pageSize);
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form.province = this.form.cityList[0];
          this.form.city = this.form.cityList[1];
          this.form.district = this.form.cityList[2];
          saveAddress({ ...this.form, brand: this.form.brand.toString() }).then(
            (res) => {
              if (res.code === 200) {
                this.getList();
                this.dialogVisible = false;
                this.$message.success(res.msg);
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    add() {
      this.form.id = null;
      this.dialogVisible = true;
    },
    onDetails(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.form.cityList = [row.province, row.city, row.district];
      if (this.form.brand) {
        this.form.brand = this.form.brand.split(",");
      }
      this.dialogVisible = true;
    },
    onDel(row) {
      console.log("删除商品", row);
      this.$confirm("是否删除此商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        supplierAddressDel(row.id).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // async getAddress() {
    //   const res = await getAddress();
    //   if (res.data) {
    //     // this.for
    //     this.form = res.data;
    //     this.form.cityList = [
    //       res.data.province,
    //       res.data.city,
    //       res.data.district,
    //     ];
    //   }
    // },
    async getRegionList() {
      const res = await getRegionList();
      res.data.forEach((item) => {
        item.label = item.name;
        item.value = item.name;
        item.children.forEach((val) => {
          val.label = val.name;
          val.value = val.name;
          val.children.forEach((v) => {
            v.label = v.name;
            v.value = v.name;
          });
        });
      });
      this.options = res.data;

      this.getList();
    },
    handleChange() {},
    getList() {
      this.listLoading = true;
      getSupplierAddressList({ ...this.tableFrom })
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.form.name = "";
      this.form.province = "";
      this.form.city = "";
      this.form.district = "";
      this.form.address = "";
      this.form.mobile = "";
      this.form.cityList = [];
      this.dialogVisible = false;
    },
    pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.headers {
  display: flex;
  align-items: center;
  .title {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.85);
  }

  .orderno {
    font-weight: 500;
    font-size: 14px;
    color: #606266;
  }
}

/deep/.el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  padding-bottom: 30px;
}

.title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
}
</style>
